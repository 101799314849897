import React from 'react';
import MainLayout from './layouts/MainLayout';
import './App.css';
import MainPicture from '../src/components/MainPicture';


function App() {
  return (
    <main>
      {/* <h2>Willkommen auf meiner Webseite!</h2>
      <p>Dies ist der Hauptinhalt. Test nach run deploy ohne zu pushen.
      </p> */}
     {/*  <div className="section1">
        <div className="backgroundBlock"></div>
      </div> */}
      
      <div className="section2">
        <div id="s2container">
          <div id="textbox">
          <h1 id="h1p">Ich entwickle deine Ideen.</h1>
          <p id="s1p">Präzise und detailliert nach deinen Vorstellungen.</p>
        </div>
        
        </div>
      </div>

      <div className="section3">


        <div id="Schritte">
          <div className="Schrittbox">
            <h2 className="SchritteÜberschrift">Schritt 1</h2>
            <p className="SchritteText">Du erklärst mir genau, wie du dir dein Produkt vorstellst und welche Details du dir wünschst</p>
          </div>

          <div className="Schrittbox">
            <h2 className="SchritteÜberschrift">Schritt 2</h2>
            <p className="SchritteText">Du lehnst dich zurück und wartest auf den ersten Entwurf</p>
          </div>

          <div className="Schrittbox" id="letzteSchrittbox">
            <h2 className="SchritteÜberschrift">Schritt 3</h2>
            <p className="SchritteText">Du gibst mir dein Feedback und ich setze es um. Und das wiederholen wir so lange, bis du zu 100% zufrieden bist.</p>
          </div>
        </div>
      </div>


{/*       <div className="container">
      <div id="Schritte">
        </div>
      
      <div className="row">
      

        <div className="col">
        <h2 className="SchritteÜberschrift">Schritt 1</h2>
        <p className="SchritteText">Du erklärst mir genau, wie du dir dein Produkt vorstellst und welche Details du brauchst</p>
      
          
        </div>
        <div className="col">
        <h2 className="SchritteÜberschrift">Schritt 2</h2>
        <p className="SchritteText">Du lehnst dich zurück und wartest auf den ersten Entwurf</p>
          
      </div>
      <div className="col">
      <h2 className="SchritteÜberschrift">Schritt 3</h2>
      <p className="SchritteText">Du gibst dein Feedback und ich setze das um. Und das wiederholen wir so lange, bis du zu 100% zufrieden bist.</p>
      
          
      </div>
      </div>
    </div>
         */}
        
{/* 
      <div className="container">
      
      
      <div className="row">

        <div className="col">
      
          
        </div>
      </div>
    </div> */}
    </main>
  );
}

export default App;
